<template>
     <!-- Content -->
    <div class="col-xl-12 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">

        <div class="user-profile layout-spacing">
            <div class="widget-content widget-content-area">
                <div class="d-flex justify-content-between">
                    <h3 class="">Test Result</h3>
                    <!-- <a href="edit_test_result.php" class="mt-2 edit-profile"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                        
                    </a> -->
                </div>
                <div class="text-center user-info">
                    <img :src="result.test_image_url || 'https://image.shutterstock.com/image-photo/does-random-employee-drug-testing-260nw-1211128711.jpg'" alt="avatar" height="300">
                    <p class="">{{ result.first_name}} {{ result.last_name }}</p>
                </div>
                <div class="user-info-list">

                    <div class="">
                        <ul class="contacts-block list-unstyled">
                            <li class="contacts-block__item">
                                <span class="text-primary">Test type: </span> <span class="bold">{{ result.test_type }}</span>
                            </li>
                            <li class="contacts-block__item">
                                <span class="text-primary">AI test result: </span> <span class="bold">{{ result.aitest_result_label }}</span>
                            </li>
                            <li class="contacts-block__item">
                                <span class="text-primary">AI result confidence: </span> <span class="bold">{{ result.aitest_result_confidence }}</span>
                            </li>
                            <li class="contacts-block__item">
                                <span class="text-primary">Human result: </span> <span class="bold">{{result.human_result_label || "null"}}</span>
                            </li>
                            <li class="contacts-block__item">
                                <span class="text-primary">Status: </span> <span class="bold">{{result.status}}</span>
                            </li>
                            <li class="contacts-block__item" v-show="result.user_type?.length > 0">
                                <span class="text-primary">User Type: </span> <span class="bold">{{result.user_type}}</span>
                            </li>
                            <li class="contacts-block__item">
                                <span class="text-primary">Email: </span> <span class="bold">{{result.email}}</span>
                            </li>
                            <li class="contacts-block__item">
                                <span class="text-primary">Phone No: </span> <span class="bold">{{result.contact_number}}</span>
                            </li>
                            <li class="contacts-block__item">
                                <span class="text-primary">Country: </span> <span class="bold">{{result.country}}</span>
                            </li>
                            <li class="contacts-block__item">
                                <span class="text-primary">State: </span> <span class="bold">{{result.state}}</span>
                            </li>
                            <li class="contacts-block__item" v-show="result.result_confirmed_by?.length > 0">
                                <span class="text-primary">Confirmed By: </span> <span class="bold">{{result.result_confirmed_by}}</span>
                            </li>
                            <li class="contacts-block__item">
                                <span class="text-primary">Date test done: </span> <span class="bold">{{new Date(result.created_at).toLocaleString("en-GB", {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', hour12:true, minute: '2-digit',timeZone: "Europe/Paris"})}}</span>
                            </li>
                            <li class="contacts-block__item">
                                <span class="text-primary">Date updated: </span> <span class="bold">{{new Date(result.updated_at).toLocaleString("en-GB", {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', hour12:true, minute: '2-digit',timeZone: "Europe/Paris"})}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="align-right">
                    <router-link v-show="role === 'SUPER_ADMIN' || result.status === 'pending'" :to="'/update-result/'+id" class="btn btn-primary ">Confirm Test Result</router-link>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '../../components/SideBar.vue';
import axios from "axios";
import NavBar from '../../components/NavBar.vue';
import Footer from '../../components/Footer.vue';
import store from "../../store"



export default {
  name: 'Result',
	data(){
		return {
			result: {},
            role: '',
            id: this.$route.params.id
		}
	},
	components: {
		SideBar,
        NavBar,
        Footer
	},
    methods: {
        async _fetchData() {
            let loading = this.$loading.show();
            let id = this.$route.params.id
            axios
                .get('test-results/'+id)
                    .then(response => {
                        this.result = response.data.data
                        loading.hide()
                        this.$notify({
                            type: "success",
                            title: "Notification",
                            text: response.data.message,
                        });
                    })
                    .catch(err => {
                            loading.hide()
                            this.$notify({
                                type: "error",
                                title: "Notification",
                                text: err.message || "Operation Failed" ,
                            });
                        })
        },
    },created(){
this.role = store.getters.getUser.role
        this._fetchData()
    }
}    
</script>

<style scoped>
    ul{ 
        width: 100%;
        max-width: 100%;
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(3, 1fr);
    }

    .align-right{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    ul.contacts-block{
        max-width: 100% !important;
    }
</style>