<template>
        <div class="widget widget-table-one">
            <div class="widget-heading">
                <h5 class="">{{ cardTitle }}</h5>
            </div>

            <div class="widget-content">
                <slot></slot>                                
            </div>
        </div>
    <!-- <div class="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
    </div> -->
</template>

<script>
    export default {
       props: {
           cardTitle: {
               type: String, default: "Card Title"
           }
       }
    }
</script>

