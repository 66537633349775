<template>
  <div>
    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
      <router-link
        :to="{ name: 'CreateAdmin' }"
        class="btn btn-primary mb-2 text-white"
        >Create Admin</router-link
      >
    </div>

    <div class="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
      <Card cardTitle="Skrind Administrators">
        <transaction
          title="Total Admin Users"
          subTitle="Skrind Amdinistrator"
          :amount="totalAdmins"
          :inc="true"
        />
        <transaction
          title="Super Admins"
          subTitle="Skrind Amdinistrator"
          :amount="superAdmins"
          :inc="true"
        />
        <transaction
          title="Normal Admins"
          subTitle="Skrind Amdinistrator"
          :amount="normalAdmins"
          :inc="true"
        />
      </Card>
    </div>

    <Table :contents="admins" :headers="headers" :actions="actions" />
    <Modal
      id="staticBackdrop"
      title="Confirmation"
      accept="Delete Admin"
      reject="Close"
      :handler="deleteAdmin"
      :arg="[clickedAdminId]"
    >
      <template v-slot:delete>
        <p>Are you sure you want to delete {{ clickedAdminEmail }} Admin?</p>
      </template>
    </Modal>
    <Modal
      :handler="changeAdminRole"
      :arg="[clickedRole, clickedAdminId]"
      id="changeRole"
      title="Confirmation"
      accept="Change Role"
      reject="Close"
    >
      <template v-slot:change>
        <p>Change Admin {{ clickedAdminEmail }} role</p>
        <select
          v-model="clickedRole"
          class="form-select"
          aria-label="Default select example"
        >
          <option value="ADMIN">ADMIN</option>
          <option value="SUPER_ADMIN">SUPER_ADMIN</option>
        </select>
      </template>
    </Modal>
  </div>
</template>

<script>
import SideBar from "../../components/SideBar.vue";
import axios from "axios";
import NavBar from "../../components/NavBar.vue";
import Card from "../../components/Card.vue";
import Transaction from "../../components/Transaction.vue";
import Table from "../../components/Table.vue";
import Modal from "../../components/Modal.vue";
import Footer from "../../components/Footer.vue";

export default {
  name: "Admin",
  data() {
    return {
      headers: [],
      admins: [],
      totalAdmins: 0,
      superAdmins: 0,
      normalAdmins: 0,
      clickedRole: "",
      actions: [
        {
          val: "Change Role",
        },
        {
          val: "Trash",
        },
      ],
    };
  },
  components: {
    SideBar,
    NavBar,
    Card,
    Transaction,
    Table,
    Modal,
    Footer,
  },
  methods: {
    async fetch() {
      let loading = this.$loading.show();
      axios
        .get("get-all-admin-users")
        .then((res) => {
          let adminsData = res.data.data.data;
          let headers = Object.keys(res.data.data.data[0]);

          //restructuring the response from the API
          this.admins = adminsData.map((item) => {
            if (item.role[0].name == "SUPER_ADMIN") {
              this.superAdmins += 1;
            } else if (item.role[0].name == "ADMIN") {
              this.normalAdmins += 1;
            }
            return {
              ...item,
              role: item.role[0].name,
            };
          });

          this.totalAdmins = this.admins.length;
          this.headers = headers;
          loading.hide();
          this.$notify({
            type: "success",
            title: "Notification",
            text: res.data.message,
          });
        })
        .catch((err) => {
          loading.hide();
          this.$notify({
            type: "error",
            title: "Notification",
            text: err.message || "Operation Failed",
          });
        });
    },
    changeAdminRole(role, id) {
      let loading = this.$loading.show();
      axios
        .patch(`update-admin-user-role/${id}`, { role })
        .then((response) => {
          loading.hide();
          location.reload();
          this.$notify({
            type: "success",
            title: "Notification",
            text: response.data.message,
          });
        })
        .catch(() => {
          loading.hide();
          location.reload();
          this.$notify({
            type: "error",
            title: "Notification",
            text: "Failed to Re-activate kit",
          });
        });
    },
    deleteAdmin(id){
      let loading = this.$loading.show();
      // logic to delete admin
      console.log(id)
      axios
        .post(`delete-admin-user/${id}`)
        .then((response) => {
          loading.hide();
          location.reload();
          this.$notify({
            type: "success",
            title: "Notification",
            text: response.data.message,
          });
        })
        .catch(() => {
          loading.hide();
          location.reload();
          this.$notify({
            type: "error",
            title: "Notification",
            text: "Failed to Re-activate kit",
          });
        });
    },
  },
  computed: {
    clickedAdminEmail() {
      return this.$store.getters.admToDelete.email;
    },
    clickedAdminId() {
      return this.$store.getters.admToDelete.id;
    },
  },
  created() {
    this.fetch();
  },
};
</script>
