<template>
  <div>
    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
      <router-link
        v-show="role === 'SUPER_ADMIN'"
        :to="{ name: 'CreateTest' }"
        class="btn btn-primary mt-2 mb-2"
        >Create Test Type</router-link
      >
      <div class="widget widget-table-one">
        <div class="widget-heading">
          <h5 class="">Test Type</h5>
        </div>

        <div class="widget-content">
          <div class="transactions-list">
            <div class="t-item">
              <div class="t-company-name">
                <div class="t-icon">
                  <div class="icon"></div>
                </div>
                <div class="t-name">
                  <h4>Total Test Type</h4>
                  <p class="meta-date">Test Type generated</p>
                </div>
              </div>
              <div class="t-rate rate-dec">
                <p>
                  <span>{{ testCount }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Table :contents="contents" :headers="headers" :actions="actions" />

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="row layout-top-spacing" id="cancel-row">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="widget-content widget-content-area br-6">
            <div class="table-responsive mb-4 mt-4">
              <table
                id="alter_pagination"
                class="table table-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Test Type</th>
                    <th>Description</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="test in tests" :key="test.id" :value="test.name">
                    <td>{{ test.name }}</td>
                    <td>{{ test.description }}</td>
                    <td class="text-center flx">
                      <router-link
                        :to="'/update-test/' + test.id"
                        class="btn btn-primary"
                        >Edit</router-link
                      >
                      <!-- <a href="edit_test" class="btn btn-primary">Edit</a> -->
                      <button
                        class="btn btn-danger"
                        @click="deleteTest(test.id)"
                      >
                        Trash
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../../components/SideBar.vue";
import axios from "axios";
import Table from "../../components/Table.vue";
import NavBar from "../../components/NavBar.vue";
import store from "../../store";

export default {
  name: "Test",
  data() {
    return {
      tests: {},
      testCount: "0",
      actions: [
        // {
        //   btnType: "btn btn-primary",
        //   val: "View",
        // },
      ],
      role: "",
      user: {},
      contents: [],
      headers: [],
    };
  },
  components: {
    SideBar,
    NavBar,
    Table,
  },

  methods: {
    deleteTest(id) {
      let loading = this.$loading.show();

      // endpoint giving bad response
      axios
        .delete("test-types/" + id)
        .then((response) => {
          location.reload();
          loading.hide();
          this.$notify({
            type: "success",
            title: "Notification",
            text: response.data.message || "success",
          });
        })
        .catch((err) => {
          loading.hide();
          this.$notify({
            type: "error",
            title: "Notification",
            text: err.message || "Operation Failed",
          });
        });
    },
  },
  created() {
    this.role = store.getters.getUser.role;
    let loading = this.$loading.show();
    axios
      .get("test-types")
      .then((response) => {
        this.tests = response.data.data.data;
        this.testCount = this.tests.length;
        loading.hide();
        this.$notify({
          type: "success",
          title: "Notification",
          text: response.data.message || "success",
        });
      })
      .catch((err) => {
        loading.hide();
        this.$notify({
          type: "error",
          title: "Notification",
          text: err.message || "Operation Failed",
        });
      });
    axios
      .get("test-types")
      .then((response) => {
        this.headers = Object.keys(response.data.data.data[0]);
        this.contents = response.data.data.data;
        loading.hide();
        this.$notify({
          type: "success",
          title: "Notification",
          text: response.data.message,
        });
      })
      .catch((err) => {
        loading.hide();
        this.$notify({
          type: "error",
          title: "Notification",
          text: err.message || "Operation Failed",
        });
      });
  },
};
</script>

<style scoped>
.flx {
  display: flex;
  justify-content: center;
  gap: 0.4em;
}
</style>