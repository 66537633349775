<template>
	<div>
		<!-- login route is displayed here -->
		<router-view v-if="this.$route.path == '/'" />

		<div v-if="this.$route.path != '/'">
			<div class="main-container" id="container">
				<NavBar />
				<SideBar v-if="width > 991" :Role="role" />
				<MobileSidebar v-if="width < 992" />
				<div class="overlay"></div>
				<div class="cs-overlay"></div>
				<div class="search-overlay"></div>

				<div id="content" class="main-content">
					<div class="layout-px-spacing">
						<div class="row layout-top-spacing">
							<!-- Current route is dispayed here -->
							<router-view />
							<notifications position="bottom right" />
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Footer from "./components/Footer.vue";
import MobileSidebar from "./components/MobileSidebar.vue";
import NavBar from "./components/NavBar.vue";
import SideBar from "./components/SideBar.vue";
import store from "./store";
export default {
	components: {
		SideBar,
		NavBar,
		Footer,
		MobileSidebar,
	},
	data() {
		return {
			role: "",
		};
	},
	created() {
		this.role = store.getters.getUser.role
	},
	computed: {
		width() {
			return window.innerWidth;
		},
	},
};
</script>

<style>
#app {
	font-family: "Nunito", sans-serif;
}

/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
