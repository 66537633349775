<template>
    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
        <router-link :to="{name: 'Create Store'}" class="btn btn-primary mt-2 mb-2">Create Store</router-link>
        
        <Card cardTitle="Store Metrics">
            <Transaction title="Total Store" subTitle="stores created" :amount="stores.length" :inc="true"/>
        </Card>
    </div>
    <div>
        <h5>Stores</h5>
        <Table :headers="header" :actions="actions" :contents="content"  />
    </div>
    <div>
        <Modal :handler="deleteAdmin" :arg="[storeIdToDelete]" id="delete-store" accept="Delete" reject="Abort" title="Delete Store?"  >
            <p>Are you sure you want to delete <span id="store-name">{{storeNameToDelete}}</span> Store?</p>
        </Modal>
    </div>
</template>

<script>
import Card from '../../components/Card.vue'
import Modal from '../../components/Modal.vue'
import Table from '../../components/Table.vue'
import Transaction from '../../components/Transaction.vue'
    export default {
        data() {
            return{
                stores: [],
                header: [],
                content: [],
                actions: [
                    {
                        btnType: "btn btn-primary",
                        val: "View"
                    },
                    {
                        btnType: "btn btn-danger",
                        val: "Trash Admin",
                    }
                ]
            }
        },
        components: { Card, Transaction, Table, Modal },
        computed: {
            storeNameToDelete () {
                return this.$store.getters.getSelectedStoreName.name
            },
            storeIdToDelete () {
                return this.$store.getters.getSelectedStoreName.id
            }
        },
        methods: {
            getStore() {
                return axios.get("locate-kit-stores")
            },
            async _fetch(){
                let loading = this.$loading.show();
                Promise.all([this.getStore()])
                    .then(response => {
                        this.stores = response[0].data.data.data
                        this.header = this.extratKeys(Object.keys(this.stores[0]))
                        this.content = this.extract(this.stores)
                        loading.hide()
                        this.$notify({
                            type: "success",
                            title: "Notification",
                            text: response[0].data.message || "success",
                        });
                    })
                    .catch(err => {
                        console.log(err);
                        loading.hide()
                        this.$notify({
                            type: "error",
                            title: "Notification",
                            text: err.message || "Operation Failed" ,
                        });
                    })
            },
            extract(arr){
                let data = []
                arr.forEach(({ id, name, address, created_at }) => {
                    data.push({ id, name, address, created_at })
                })
                return data
            },
            extratKeys(arr) {
                let data = []
                arr.forEach(i => {
                    if (i != "image_url" && i != "updated_at") {
                        data.push(i)
                    }
                })
                return data
            },
            deleteAdmin(id) {
                let loading = this.$loading.show();
                axios.delete(`locate-kit-stores/${id}`)
                    .then(response => {
                        loading.hide()
                        location.reload();
                        console.log(response);
                        this.$notify({
                            type: "success",
                            title: "Notification",
                            text: response.data.message || "success",
                        });
                    })
                    .catch(err => {
                        console.log(err);
                        loading.hide()
                        location.reload();
                        this.$notify({
                            type: "error",
                            title: "Notification",
                            text: err.message || "Operation Failed" ,
                        });
                    })
            }
        },
        created() {
            this._fetch()
        }    
    }
</script>

<style scoped>
#store-name{
    font-weight: 900;
}
</style>