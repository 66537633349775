import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from "axios";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Notifications from '@kyvg/vue3-notification';
// import $ from 'jquery';



// Styles
import './assets/bootstrap/css/bootstrap.min.css';
import './assets/assets/css/plugins.css';
import './assets/assets/css/authentication/form-2.css';
import './assets/plugins/apex/apexcharts.css';
import './assets/assets/css/dashboard/dash_2.css';
import './assets/plugins/table/datatable/datatables.css';
import './assets/plugins/table/datatable/dt-global_style.css';
import './assets/assets/css/forms/theme-checkbox-radio.css';
import './assets/assets/css/forms/switches.css';
import './assets/plugins/dropify/dropify.min.css';
import './assets/assets/css/users/account-setting.css';
import './assets/assets/css/users/user-profile.css';

//JavaScript
// import './assets/assets/js/loader.js';
import './assets/assets/js/libs/jquery-3.1.1.min.js';
import './assets/bootstrap/js/popper.min.js';
import './assets/bootstrap/js/bootstrap.min.js';
import './assets/plugins/perfect-scrollbar/perfect-scrollbar.min.js';
import './assets/assets/js/app.js';
import './assets/init.js';
// import './assets/assets/js/custom.js';
// import './assets/plugins/apex/apexcharts.min.js';
// import './assets/assets/js/dashboard/dash_2.js';
// import './assets/assets/js/custom.js';
// import './assets/plugins/table/datatable/datatables.js';
// import './assets/assets/js/datatable.js';
import './assets/table.js';



createApp(App)
    .use(VueLoading, { isFullPage: false })
    .use(router)
    .use(store)
    .use(Notifications)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");

    const token = store.getters.token
    if (token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
    }
    // axios.defaults.baseURL = 'http://127.0.0.1:8000/api/v1';
    // axios.defaults.baseURL = 'https://skrind.tk/api/v1/';
    axios.defaults.baseURL = 'https://skrind.com.ng/api/v1';
    window.axios = axios;
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    window.axios.defaults.withCredentials = true;
    // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'


