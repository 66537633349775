<template>
    <!-- Modal -->
    <div class="modal fade" :id="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ title }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <slot name="delete"></slot>
            <slot name="change"></slot>
            <slot></slot>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">{{ reject }}</button>
            <button @click="handler(...arg)" type="button" class="btn btn-danger">{{ accept }}</button>
        </div>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        data() {
            return{
                clickedAdminEmail: "",
                clickedAdminRole: "",
            }
        },
        props: {
            title: { type: String },
            id: { type: String, default: "staticBackdrop" },
            accept: {type: String },
            reject: { type: String},
            handler: { type: Function },
            arg: { type: Array }
        },
        methods: {
            setClickedAdmin(email) {
            this.clickedAdminEmail = email
            // this.clickedAdminRole = role
        }
        }
    }
</script>
