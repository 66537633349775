<template>
  <main class="sidebar-noneoverflow">
    <div class="row">
      <!-- For Hepatitis B -->
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        <Card cardTitle="Last 24 Hours Hepatitis B Test">
          <Transaction
            v-for="data in sData1"
            :key="data.title"
            :subTitle="data.subTitle"
            :title="data.title"
            :amount="data.amount"
            :inc="data.inc"
          />
        </Card>
      </div>

      <div class="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
        <Card cardTitle="All Time Hepatitis B Test">
          <Transaction
            v-for="data in sData1"
            :key="data.title"
            :subTitle="data.subTitle"
            :title="data.title"
            :amount="data.amount"
            :inc="data.inc"
          />
        </Card>
      </div>

      <!-- For HIV 1/2 -->

       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        <Card cardTitle="Last 24 Hours HIV1/2 Test">
          <Transaction
            v-for="data in sData1"
            :key="data.title"
            :subTitle="data.subTitle"
            :title="data.title"
            :amount="data.amount"
            :inc="data.inc"
          />
        </Card>
      </div>

      <div class="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
        <Card cardTitle="All Time HIV1/2 Test">
          <Transaction
            v-for="data in sData1"
            :key="data.title"
            :subTitle="data.subTitle"
            :title="data.title"
            :amount="data.amount"
            :inc="data.inc"
          />
        </Card>
      </div>

      <!-- For Hepatitis C -->

       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        <Card cardTitle="Last 24 Hours Hepatitis C Test">
          <Transaction
            v-for="data in sData1"
            :key="data.title"
            :subTitle="data.subTitle"
            :title="data.title"
            :amount="data.amount"
            :inc="data.inc"
          />
        </Card>
      </div>

      <div class="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
        <Card cardTitle="All Time Hepatitis C Test">
          <Transaction
            v-for="data in sData1"
            :key="data.title"
            :subTitle="data.subTitle"
            :title="data.title"
            :amount="data.amount"
            :inc="data.inc"
          />
        </Card>
      </div>

      <!-- For Malaria -->

       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        <Card cardTitle="Last 24 Hours Malaria Test">
          <Transaction
            v-for="data in sData1"
            :key="data.title"
            :subTitle="data.subTitle"
            :title="data.title"
            :amount="data.amount"
            :inc="data.inc"
          />
        </Card>
      </div>

      <div class="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
        <Card cardTitle="All Time Malaria Test">
          <Transaction
            v-for="data in sData1"
            :key="data.title"
            :subTitle="data.subTitle"
            :title="data.title"
            :amount="data.amount"
            :inc="data.inc"
          />
        </Card>
      </div>

    </div>
  </main>
</template>

<script>
import SideBar from "../components/SideBar.vue";
import axios from "axios";
import NavBar from "../components/NavBar.vue";
import Transaction from "../components/Transaction.vue";
import Card from "../components/Card.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Dashboard",
  data() {
    return {
      // sData1 should be fetched from the server and should be for the last 24hours
      sData1: [
        {
          title: "Awaiting Tests",
          subTitle: "Test Awaiting Response",
          amount: 300,
          inc: false,
        },
        {
          title: "Confirmed Test",
          subTitle: "Test Analyzed",
          amount: 300,
          inc: true,
        },
        {
          title: "Diagnosed Cases",
          subTitle: "Tests confirmed as positive/reactive",
          amount: 150,
          inc: true,
        },
      ],

      //sData2 should be fetched from the server and should be all time data
      sData2: [
        {
          title: "Admins",
          subTitle: "Number of Admins",
          amount: 5,
          inc: true,
        },
        {
          title: "Users",
          subTitle: "Number of Users",
          amount: 100,
          inc: true,
        },
        {
          title: "Test Taken",
          subTitle: "Total Number of Test Taken",
          amount: 0,
          inc: true,
        },
      ]
      
    };
  },
  components: {
    SideBar,
    NavBar,
    Transaction,
    Card,
    Footer,
  },
  created() {
    let loading = this.$loading.show();
    axios
      .get("get-admin-logged-in-user")
      .then((response) => {
        this.user = response.data.data;
        this.$store.commit("setUser", this.user);
        loading.hide();
      })
      .catch((err) => {
        loading.hide();
        this.$notify({
          type: "error",
          title: "Notification",
          text: err.message || "Operation Failed",
        });
      });
    
  },
};
</script>
