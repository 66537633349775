<template>
    <main class="sidebar-noneoverflow">
    <div class="account-content">
    <div class="scrollspy-example" data-spy="scroll" data-target="#account-settings-scroll" data-offset="-100">
        <div class="row">

            <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <form @submit.prevent="updateStore(id)" id="contact" class="section contact">
                    <div class="info">
                        <h5 class="">{{name}} Store</h5>
                        <div class="row">
                            <div class="col-md-11 mx-auto">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="country">Store Id</label>
                                            <input type="text" class="form-control mb-4" v-model="id" disabled>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="country">Store Name</label>
                                            <input type="text" class="form-control mb-4" v-model="name" >
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="country">Store Address</label>
                                            <input type="text" class="form-control mb-4" v-model="address">
                                        </div>
                                    </div>                                  
                                    <div class="col-md-12">
                                        <div class="form-group custom-form-group"> 
                                            <div class="form-label-custom mb-4">
                                                <label for="formFile" class="form-label">{{logo}}</label>
                                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.44 11.0499L11.25 20.2399C10.1242 21.3658 8.59723 21.9983 7.00505 21.9983C5.41286 21.9983 3.88589 21.3658 2.76005 20.2399C1.6342 19.1141 1.00171 17.5871 1.00171 15.9949C1.00171 14.4027 1.6342 12.8758 2.76005 11.7499L11.95 2.55992C12.7006 1.80936 13.7186 1.3877 14.78 1.3877C15.8415 1.3877 16.8595 1.80936 17.61 2.55992C18.3606 3.31048 18.7823 4.32846 18.7823 5.38992C18.7823 6.45138 18.3606 7.46936 17.61 8.21992L8.41005 17.4099C8.03476 17.7852 7.52577 17.996 6.99505 17.996C6.46432 17.996 5.95533 17.7852 5.58005 17.4099C5.20476 17.0346 4.99393 16.5256 4.99393 15.9949C4.99393 15.4642 5.20476 14.9552 5.58005 14.5799L14.07 6.09992" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                                <input @change="setLabel"  class="mb-4" type="file" id="formFile" required>
                                            </div>
                                        </div>
                                    </div>                                  
                                </div>
                                <button type="submit" class="btn btn-primary" >Save Changes</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
    </main>
</template>

<script>
    export default {
        data(){
            return {
                store: {},
                id: "",
                name: "",
                address: "",
                img: "",
                logo: "Please select old logo or a new logo"
            }
        },
        methods: {
            _fetch() {
                this.store = this.$store.getters.getCurrViewStore
                this.id = this.store["id"]
                this.name = this.store["name"]
                this.address = this.store["address"]
            },
            updateStore(id) {
                let loading = this.$loading.show()
                const form = new FormData()
                form.append("image", this.img)
                form.append("name", this.name)
                form.append("address", this.address)
                axios.post(`locate-kit-stores/${this.store["id"]}`, form)
                    .then(response => {
                        loading.hide()
                        this.$router.push(`/store/${id}`)
                        this.$notify({
                            type: "success",
                            title: "Notification",
                            text: response.data.message || "success",
                        });
                    })
                    .catch(err => {
                        console.log(err);
                        loading.hide()
                        this.$notify({
                            type: "error",
                            title: "Notification",
                            text: err.message || "Operation Failed" ,
                        });
                    })
            },
            setLabel(e){
                // Get the selected file
                const [file] = e.target.files;
                this.img = file
                // Get the file name and size
                const { name: fileName, size } = file;
                // Convert size in bytes to kilo bytes
                const fileSize = (size / 1000).toFixed(2);
                // Set the text content
                const fileNameAndSize = `${fileName} - ${fileSize}KB`;
                this.logo = fileNameAndSize;
            }
        },
        created(){
            this._fetch()
        }
    }
</script>

<style scoped>

</style>