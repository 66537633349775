<template>	
<main class="sidebar-noneoverflow">
    <div class="account-content">
        <div class="scrollspy-example" data-spy="scroll" data-target="#account-settings-scroll" data-offset="-100">
            <div class="row">

                <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                    <form @submit.prevent="createKit()" id="contact" class="section contact">
                            <Card cardTitle="Kit Code" >                                            
                                <div class="row">
                                    <div class="col-md-11 mx-auto">
                                        <div class="row">
                                            <div class="col-md-12">
                                    
                                                <div class="form-group">
                                                    <label for="country">Test Type</label>
                                                    <select class="form-control form-select" v-model="test_name" required>
                                                        <option selected>{{ test_name }}</option>
                                                        <option v-for="test in tests" :key="test.id" :value="test.name">{{test.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="address">Number of kits to be Created</label>
                                                    <input type="number" class="form-control mb-4" v-model="amount" placeholder="Enter number to be created" required>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="btn btn-primary">Create Kits</button>
                                    </div>
                                </div>
                            </Card>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main>
</template>

<script>
import axios from 'axios';
import NavBar from '../../components/NavBar.vue';
import Card from '../../components/Card.vue';
import Footer from '../../components/Footer.vue';

export default {
	name: 'CreateKit',
	data(){
			return{
				tests:[],
				test_name:"Select a test type",
				user_id:"",
				amount:""
			}
	},
	components:{
        NavBar,
        Card,
        Footer
	},
	methods:{
		async fetch(){
			let loading = this.$loading.show();
			axios
				.get('test-types')
					.then(response => {
						this.tests = response.data.data.data
                        loading.hide()
                        this.$notify({
                            type: "success",
                            title: "Notification",
                            text: response.data.message || "success",
                        });
					})
                    .catch(err => {
                        console.log(err);
                        loading.hide()
                        this.$notify({
                            type: "error",
                            title: "Notification",
                            text: err.message || "Operation Failed" ,
                        });
                    })											
		},
		createKit(){
            let loading = this.$loading.show();   
            let _id;

            // set post request id to appropriate id
            for (let index = 0; index < this.tests.length; index++) {
                const element = this.tests[index];
                if (element.name == this.test_name) {
                    _id = element.id
                }
                
            };            
			let body = {
				test_type_id: _id,
				number: this.amount
			}
			axios
				.post('kit-codes/create-multiple-kit-codes', body)
					.then((response) => {
						console.log('done')
						this.$router.push('/kits')
                        loading.hide()
                        this.$notify({
                            type: "success",
                            title: "Notification",
                            text: response.data.message || "success",
                        });
					})
                    .catch(err => {
                        console.log(err);
                        loading.hide()
                        this.$notify({
                            type: "error",
                            title: "Notification",
                            text: err.message || "Operation Failed" ,
                        });
                    })
		}

	},
	created(){
		this.fetch();
	}
}
</script>

<style>

</style>