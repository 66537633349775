<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
    <div class="row layout-top-spacing" id="cancel-row">
      <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
        <div class="widget-content widget-content-area br-6">
          <!-- search bar will go here -->
          <ul class="navbar-item flex-row search-ul">
            <li class="nav-item align-self-center search-animated">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-search toggle-search"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
              <form
                class="form-inline search-full form-inline search"
                role="search"
              >
                <div class="search-bar">
                  <input
                    @input="search(contents, setFilteredData)"
                    type="text"
                    class="form-control search-form-control ml-lg-auto"
                    placeholder="Search..."
                  />
                </div>
              </form>
            </li>
          </ul>
          <div class="table-responsive mb-4 mt-4">
            <table
              id="#alter_pagination"
              class="table table-hover"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th v-for="head in headers" :key="head.name">{{ head }}</th>
                  <th v-show="actions?.length > 0" class="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="content in chunks[activePageNum]"
                  :key="content.id"
                  :value="content.name"
                >
                  <td v-for="h in headers" :key="h.id">
                    <span :class="getFielsDataClass(content[h])">{{
                      content[h]
                    }}</span>
                  </td>
                  <!-- <td v-for="h in headers" :key="h.id" ><span >{{ content[h] }}</span></td> -->
                  <td class="text-center flx">
                    <router-link to=""></router-link>
                    <button
                      @click="
                        handleClick(
                          content.email,
                          content.role,
                          content.id,
                          content.code,
                          action.val,
                          content.name
                        )
                      "
                      data-bs-toggle="modal"
                      :data-bs-target="checkTarget(action.val)"
                      v-for="action in actions"
                      :key="action.val"
                      :class="getActionClass(action.val, content.status)"
                    >
                      {{ action.val }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav aria-label="Page navigation example">
    <ul class="paginate">
      <li @click="prevPage()" class="pa-prev disabled">
        <p>Previous</p>
      </li>
      <li
        v-for="num in chunkSize"
        :key="num"
        @click="setActivePage(num)"
        :class="getActivePage(num)"
      >
        <p>{{ num }}</p>
      </li>
      <li @click="nextPage()" class="pa-next">
        <p>Next</p>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      clickedAdminEmail: "",
      clickedAdminRole: "",
      newRole: "",
      activePageNum: 0,
      chunks: [],
      filteredResult: [],
      chunkSize: 0,
    };
  },
  props: {
    headers: {
      type: Array,
      default: ["Disease", "Test Taken", "Awaiting Test", "Total Test Taken"],
    },
    actions: {
      type: Array,
      default: [
        {
          btnType: "btn btn-primary",
          val: "Change Role",
        },
        {
          btnType: "btn btn-danger",
          val: "trash ",
        },
      ],
    },
    contents: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.filteredResult = this.$props.contents;
  },
  watch: {
    //watches for changes in the contents props
    contents: function(){
      this.filteredResult = this.$props.contents;
    },
    filteredResult: function (newV) {
      this.chunks = this.chunkArr(newV, 10);
      this.chunkSize = this.chunks.length;
    },
  },
  mounted() {
    //runs when the entire view has been rendered upon the update
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been re-rendered
      this.chunks = this.chunkArr(this.$props.contents, 10);
      this.chunkSize = this.chunks.length;
    });
  },
  methods: {
    // search through new data
    search(searchData, setFilteredData) {
      const keyword = event.target.value;
      console.log('This is',keyword)
      const result = searchData.filter((data) => {
        return Object.keys(data).some((key) => {
          return JSON.stringify(data[key])
            .toLowerCase()
            .trim()
            .includes(keyword);
        });
      });

      setFilteredData(result);
    },

    setFilteredData(data) {
      this.filteredResult = data;
    },

    //returns to the previous active page number
    prevPage() {
      this.activePageNum > 0 ? this.activePageNum-- : null;
    },

    //goes to the next active page number
    nextPage() {
      this.activePageNum < this.chunkSize - 1 ? this.activePageNum++ : null;
    },
    //sets the active class for the active page number in the navigator
    getActivePage(num) {
      switch (num) {
        case this.activePageNum + 1:
          return "pa-item active";
        default:
          return "pa-item";
      }
    },

    //Handles the action button click event
    handleClick(email, role, id, kitCode, val, name) {
      switch (this.$route.name) {
        case "ConfirmedResults":
          this.$router.push(`/result/${id}`);
          break;
        case "AwaitingResults":
          this.$router.push(`/result/${id}`);
          break;
        case "Results":
          this.$router.push(`/result/${id}`);
          break;
        case "Admin":
          let data = {
            email,
            role,
            id,
          };
          this.$store.commit("admToDelete", data);
          break;
        case "Kit":
          this.$store.commit("setSelectedKitCode", kitCode);
        case "Stores":
          if (val == "Trash Admin") {
            this.$store.commit("setSelectedStoreName", { name, id });
          } else if (val == "View") {
            this.$router.push(`/store/${id}`);
          }
          break;

        default:
          break;
      }
      // if (this.$route.name == "ConfirmedResults" || this.$route.name == "AwaitingResults") {
      //     this.$router.push(`/result/${id}`)
      // } else {
      //     let data = {
      //         email,
      //         role
      //     }
      //     this.$store.commit("admToDelete", data)
      // }
    },
    //determines the class the admin button gets depending on the acess of the logged in user
    getFielsDataClass(arg) {
      switch (arg) {
        case "SUPER_ADMIN":
          return "badge outline-badge-success";
        case "ADMIN":
          return "badge outline-badge-primary";
        case "ACTIVE":
          return "badge outline-badge-success";
        case "USED":
          return "badge outline-badge-danger";
        case "CONFIRMED":
          return "badge outline-badge-primary";
        case "UNCONFIRMED":
          return "badge outline-badge-warning";
        case "pending":
          return "badge outline-badge-warning";
        case "Confirmed":
          return "badge outline-badge-primary";

        default:
          return "";
      }
    },
    //determines the class the action button gets
    getActionClass(val, status) {
      switch (status) {
        case "ACTIVE":
          return "btn btn-primary disabled";
        case "USED":
          return "btn btn-primary";
      }

      switch (this.$store.getters.getUser.role) {
        case "SUPER_ADMIN":
          if (val == "Change Role") {
            return "btn btn-primary";
          } else if (val == "Trash") {
            return "btn btn-danger";
          } else if (val == "Trash Admin") {
            return "btn btn-danger";
          }

        case "ADMIN":
          if (val == "Change Role") {
            return "btn btn-primary disabled";
          } else if (val == "Trash") {
            return "btn btn-danger disabled";
          } else if (val == "Trash Admin") {
            return "btn btn-danger";
          }
        default:
          return "btn btn-primary";
      }
    },
    // returns the target id for respective modals
    checkTarget(val) {
      switch (val) {
        case "Trash":
          return "#staticBackdrop";
        case "Change Role":
          return "#changeRole";
        case "Reactivate":
          return "#reactivate";
        case "Trash Admin":
          return "#delete-store";

        default:
          return "#none";
          break;
      }
    },
    //breaks the array into chunks
    chunkArr(arr, sizePerChunk) {
      const chunks = [];
      for (let i = 0; i < arr.length; ) {
        chunks.push(arr.slice(i, sizePerChunk + i));
        i += sizePerChunk;
      }
      return chunks;
    },
    //sets the active page number
    setActivePage(pageNumber) {
      this.activePageNum = pageNumber - 1;
    },
  },
};
</script>


<style scoped>
.flx {
  display: flex;
  justify-content: center;
  gap: 0.4em;
}

p {
  margin: 0;
}

.paginate {
  display: flex;
  list-style: none;
}

.paginate li {
  display: flex;
  align-content: center;
}

.pa-item,
.pa-next,
.pa-prev {
  padding: 0.5em;
  border: 1px solid #f1f0f0;
  cursor: pointer;
}

.active,
.active p {
  background: #1b55e2;
  color: #fff;
}

.navbar-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-item {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: solid 1px #e0e6ed;
  border-radius: 6px;
  padding: 2px 5px;
}

.nav-item input {
  border: none;
  width: 400px;
  height: 36px;
}
</style>
