<template>
    <div>
        <h1>Sorry, you do not have access to this section</h1>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>