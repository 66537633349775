<template>
  <main class="sidebar-noneoverflow">
    <div class="account-content">
      <div
        class="scrollspy-example"
        data-spy="scroll"
        data-target="#account-settings-scroll"
        data-offset="-100"
      >
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
            <form
              @submit.prevent="createTest"
              id="contact"
              class="section contact"
            >
              <div class="info">
                <h5 class="">Test Type</h5>
                <div class="row">
                  <div class="col-md-11 mx-auto">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="test_type">Test Type</label>
                          <input
                            type="text"
                            class="form-control mb-4"
                            id="test_type"
                            v-model="name"
                            placeholder="Enter test name"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="description">Description</label>
                          <textarea
                            class="form-control mb-4"
                            v-model="description"
                          >
Description</textarea
                          >
                        </div>
                      </div>
                    </div>
                    <button class="btn btn-primary">Create Test Type</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SideBar from "../../components/SideBar.vue";
import axios from "axios";
import NavBar from "../../components/NavBar.vue";
import Footer from "../../components/Footer.vue";

export default {
  name: "CreateTest",
  data() {
    return {
      test: {},
      name: "",
      description: "",
      amount: "0",
    };
  },
  components: {
    SideBar,
    NavBar,
    Footer,
  },
  methods: {
    createTest() {
      let loading = this.$loading.show();
      let data = {
        name: this.name,
        amount: 1000,
        description: this.description,
      };
      axios
        .post("test-types", data)
        .then((response) => {
          console.log("done");
          this.$router.push("/tests");
          loading.hide();
          this.$notify({
            type: "success",
            title: "Notification",
            text: response.data.message || "success",
          });
        })
        .catch((err) => {
          loading.hide();
          this.$notify({
            type: "error",
            title: "Notification",
            text: err.message || "Operation Failed",
          });
        });
    },
  },
};
</script>

<style>
</style>