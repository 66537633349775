<template>
  <main class="sidebar-noneoverflow">
    <div class="account-content">
      <div
        class="scrollspy-example"
        data-spy="scroll"
        data-target="#account-settings-scroll"
        data-offset="-100"
      >
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
            <form
              @submit.prevent="updateResult(result.test_id)"
              id="contact"
              class="section contact"
            >
              <div class="info">
                <h5 class="">{{ result.first_name }}'s Test</h5>
                <div class="row">
                  <div class="col-md-11 mx-auto">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="country">Test Type</label>
                          <input
                            type="text"
                            class="form-control mb-4"
                            v-model="result['test_type']"
                            placeholder="Malaria"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="country">AI Prediction Label</label>
                          <input
                            type="text"
                            class="form-control mb-4"
                            v-model="result['aitest_result_label']"
                            placeholder="Malaria"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="country">AI Prediction Percent</label>
                          <select
                            class="form-control form-select"
                            v-model="result['aitest_result_confidence']"
                            disabled
                          >
                            <option selected>
                              {{
                                result.aitest_result_confidence ||
                                "non selected"
                              }}
                            </option>
                            <option>negative</option>
                            <option>positive</option>
                          </select>
                        </div>
                      </div>
                      <div
                        v-if="result.result_confirmed_by != null"
                        class="col-md-6"
                      >
                        <div class="form-group">
                          <label for="country">Confirmed By</label>
                          <select
                            class="form-control"
                            id="confirmed_by"
                            disabled
                          >
                            <option>{{ result.result_confirmed_by }}</option>
                            <!-- <option>Joe Biden</option> -->
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="country">Test Status</label>
                          <select
                            class="form-control form-select"
                            v-model="result['status']"
                            disabled
                          >
                            <option>
                              {{ result.status || "non selected" }}
                            </option>
                            <!-- <option>awaiting</option>
                                                <option>confirmed</option> -->
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                          <label for="country">Select Final Result</label>
                          <select class="form-control form-select" v-model="finalResult" v-show="result.test_type !== 'HIV1/2'">
                            <!-- <option selected></option> -->
                            <option value="Negative">Negative</option>
                            <option value="Positive">Positive</option>
                            <option value="Invalid">Invalid</option>
                          </select>
                          <select class="form-control form-select" v-model="finalResult" v-show="result.test_type === 'HIV1/2'">
                            <!-- <option selected></option> -->
                            <option value="Negative">Non-reactive</option>
                            <option value="Positive">Reactive</option>
                            <option value="Invalid">Invalid</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <button class="btn btn-primary">Save Changes</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SideBar from "../../components/SideBar.vue";
import axios from "axios";
import NavBar from "../../components/NavBar.vue";


export default {
  name: "UpdateResult",
  data() {
    return {
      user: {},
      result: {},
      finalResult: "",
      role: '',
    };
  },
  components: {
    SideBar,
    NavBar,
  },
  created() {
    
    let loading = this.$loading.show();
    let id = this.$route.params.id;
    axios
      .get("test-results/" + id)
      .then((response) => {
        this.result["first_name"] = response.data.data.first_name;
        this.result["test_id"] = response.data.data.test_id;
        this.result["last_name"] = response.data.data.last_name;
        this.result["test_type"] = response.data.data.test_type;
        this.result["status"] = response.data.data.status;
        this.result["aitest_result_label"] =
          response.data.data.aitest_result_label;
        this.result["aitest_result_confidence"] =
          response.data.data.aitest_result_confidence;
        loading.hide();
        this.$notify({
          type: "success",
          title: "Notification",
          text: response.data.message || "success",
        });
      })
      .catch((err) => {
        loading.hide();
        this.$notify({
          type: "error",
          title: "Notification",
          text: err.message || "Operation Failed",
        });
      });
  },
  methods: {
    updateResult(id) {
      let loading = this.$loading.show();
      let data = {
        human_result_label: this.finalResult,
      };
      axios
        .patch("test-results/confirm-user-test-result/" + id, data)
        .then(() => {
          loading.hide();
          console.log("done");
          this.$router.replace("/result/" + id);
        })
        .catch((err) => {
          loading.hide();
          this.$notify({
            type: "error",
            title: "Notification",
            text: err.message || "Operation Failed",
          });
        });
    },
  },
};
</script>

<style>
</style>