<template>
  <main class="sidebar-noneoverflow">
    <div class="account-content">
      <div
        class="scrollspy-example"
        data-spy="scroll"
        data-target="#account-settings-scroll"
        data-offset="-100"
      >
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
            <form id="contact" class="section contact">
              <div class="info">
                <h5 class="">Administrator</h5>
                <div class="row">
                  <div class="col-md-11 mx-auto">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="country">Role</label>
                          <select class="form-control" id="country">
                            <option selected>Administrator</option>
                            <option>Super Administrator</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="address">Email</label>
                          <input
                            type="email"
                            class="form-control mb-4"
                            id="email"
                            placeholder="johndoe@gmail.com"
                            v-model="user['email']"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="firstname">First Name</label>
                          <input
                            type="text"
                            class="form-control mb-4"
                            id="firstname"
                            placeholder="First name"
                            v-model="user['first_name']"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="lastname">Last Name</label>
                          <input
                            type="text"
                            class="form-control mb-4"
                            id="lastname"
                            placeholder="Last name"
                            v-model="user['last_name']"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="phone">Phone Number</label>
                          <input
                            type="text"
                            class="form-control mb-4"
                            id="phone"
                            placeholder="+23487543321"
                            v-model="user['contact_number']"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="website">Website</label>
                          <input
                            type="text"
                            class="form-control mb-4"
                            id="website"
                            placeholder="www.johndoe.com"
                          />
                        </div>
                      </div>
                    </div>
                    <button class="btn btn-primary" @click="updateUser()">
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SideBar from "../../components/SideBar.vue";
import axios from "axios";
import NavBar from "../../components/NavBar.vue";

export default {
  name: "UpdateUser",
  components: {
    SideBar,
    NavBar,
  },
  methods: {
    updateUser() {
      let loading = this.$loading.show();
      let data = {
        first_name: this.user["first_name"],
        last_name: this.user["last_name"],
        contact_number: this.user["contact_number"],
      };
      axios.patch("update-user-profile", data).then((response) => {
        loading.hide();
        this.$notify({
          type: "success",
          title: "Notification",
          text: response.data.message || "success",
        });
      });
    },
  },
};
</script>

<style>
</style>