<template>
    <div class="transactions-list">
        <div class="t-item">
            <div class="t-company-name">
                <div class="t-icon">
                    <div class="icon">
                    </div>
                </div>
                <div class="t-name">
                    <h4>{{ title }}</h4>
                    <p class="meta-date">{{ subTitle }}</p>
                </div>

            </div>
            <div :class="getIncStatus">
                <p><span>{{ amount }}</span></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String, default: "Title"
            },
            subTitle: {
                type: String, default: "number of users"
            },
            amount: {
                type: Number, default: 0
            },
            inc: {
                type: Boolean, default: true
            }
        },
        computed: {
            getIncStatus() {
                return {
                    't-rate rate-inc': this.$props.inc == true, 
                    't-rate rate-dec': this.$props.inc == false
                }
            }
        }
    }
</script>

