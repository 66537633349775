<template>
    <aside :class="getSidebarClass(display)">
        <div class="aside-container">
            <div class="sidebar-header">
                <div></div>
                <router-link class="theme-text" :to="{name: 'Home'}">
                    <h2>Skrind</h2>
                </router-link>
                <div class="btn-bck">
                    <button @click="toggleNav">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left sidebarCollapse"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                    </button>
                </div>
            </div>
            <ul>
                <li class="menu">
                <a href="" aria-expanded="false" class="dropdown-toggle">
                    <div class="flx">
                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                        <span><router-link :to="{name: 'Dashboard'}">Dashboard</router-link></span>
                    </div>
                </a>
            </li>
            <li class="menu">
                <a href="" aria-expanded="false" class="dropdown-toggle">
                    <div class="flx">
                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                        <span><router-link :to="{name: 'Admin'}">Adminstrators</router-link></span>
                    </div>
                </a>
            </li>
             <li class="menu">
                <a href="" aria-expanded="false" class="dropdown-toggle">
                    <div class="flx">
                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                        <span><router-link :to="{name: 'Kit'}">Kit Codes</router-link></span>
                    </div>
                </a>
            </li>
             <li class="menu">
                <a href="" aria-expanded="false" class="dropdown-toggle">
                    <div class="flx">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
                         <span><router-link :to="{name: 'Test'}">Test</router-link></span>
                    </div>
                </a>
            </li>
            <li class="menu">
                <a href="test_results.php" aria-expanded="false" class="dropdown-toggle">
                    <div class="flx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layout"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="3" y1="9" x2="21" y2="9"></line><line x1="9" y1="21" x2="9" y2="9"></line></svg>
                        <span><router-link :to="{name: 'Results'}">Result</router-link></span>
                    </div>
                </a>
            </li>
            <li class="menu">
                <a href="countries" aria-expanded="false" class="dropdown-toggle">
                    <div class="flx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-target"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="6"></circle><circle cx="12" cy="12" r="2"></circle></svg>
                        <span>Countries</span>
                    </div>
                </a>
            </li>
            </ul>
        </div>
    </aside>
</template>

<script>
    export default {
        methods: {
            getSidebarClass(status) {
                if (status == true) {
                    return "show-sidebar"
                } else if (status == false) {
                    return "hide-sidebar"
                }
            },
            toggleNav(){
                const status = this.$store.getters.getSideBar
                this.$store.commit("setSideBar", !status)
            }
        },
        computed: {
            display(){
                return this.$store.getters.getSideBar
            },
            routeChange() {
                return this.$route.path
            }
        },
        watch: {
            //closes navbar after route changes
            routeChange(newV, oldN) {
                if (newV != oldN) {
                    this.toggleNav()
                    console.log("route Changed");
                }
            }
        }
    }
</script>

<style scoped>
h2{
    color: #fff;
    margin: 0;
}
.sidebar-header{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 0 1em;
}
.flx{
    display: flex;
    gap: 1em;
    align-items: center;
}
button{
    background: inherit;
    border: none;
    color: #fff;
    cursor: pointer;
}
.show-sidebar{    
    transform: translateX(250px);
}

.hide-sidebar{
    transform: translateX(0px);
}

.aside-container{
    padding: 2em;
}

ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
    padding: 0;
}

.btn-bck{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

aside{
    position: absolute;
    left: -250px;
    transition: .3s ease-in-out;
    z-index: 1031;
    background-color: #060818;
    height: 100vh;
    width: 250px;    
}
</style>