<template>
  <main class="sidebar-noneoverflow">
    <!--  BEGIN MAIN CONTAINER  -->
    <div class="account-content">
      <div
        class="scrollspy-example"
        data-spy="scroll"
        data-target="#account-settings-scroll"
        data-offset="-100"
      >
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
            <form
              @submit.prevent="createAdmin()"
              id="contact"
              class="section contact"
            >
              <div class="info">
                <h5 class="">Administrator</h5>
                <div class="row">
                  <div class="col-md-11 mx-auto">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="country">Role</label>
                          <select
                            v-model="role"
                            class="form-control form-select"
                            id="country"
                          >
                            <option value="" selected>
                              Please select admin type
                            </option>
                            <option>ADMIN</option>
                            <option>SUPER_ADMIN</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="address">Email</label>
                          <input
                            v-model="email"
                            type="email"
                            class="form-control mb-4"
                            id="email"
                            placeholder="johndoe@gmail.com"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="firstname">First Name</label>
                          <input
                            type="text"
                            class="form-control mb-4"
                            id="firstname"
                            placeholder="First name"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="lastname">Last Name</label>
                          <input
                            type="text"
                            class="form-control mb-4"
                            id="lastname"
                            placeholder="Last name"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="phone">Phone Number</label>
                          <input
                            type="text"
                            class="form-control mb-4"
                            id="phone"
                            placeholder="+23487543321"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="website">Website</label>
                          <input
                            type="text"
                            class="form-control mb-4"
                            id="website"
                            placeholder="www.johndoe.com"
                          />
                        </div>
                      </div>
                    </div>
                    <button class="btn btn-primary mb-2 text-white">
                      Create Admin
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SideBar from "../../components/SideBar.vue";
import axios from "axios";
import NavBar from "../../components/NavBar.vue";

export default {
  name: "CreateAdmin",
  data() {
    return {
      user: {},
      email: "",
      role: "",
    };
  },
  components: {
    SideBar,
    NavBar,
  },
  methods: {
    createAdmin() {
      let loading = this.$loading.show();
      axios
        .post("user-invitation", {
          email: this.email,
          role: this.role,
        })
        .then((res) => {
          console.log(res);
          this.email = "";
          this.role = "";
          this.$router.push("/admin");
          loading.hide();
          this.$notify({
            type: "success",
            title: "Notification",
            text: res.data.message || "success",
          });
        });
    },
  },
};
</script>

<style>
</style>