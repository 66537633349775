<template>
  <div>
    <!--  BEGIN MAIN CONTAINER  -->
    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
      <!-- <a href="create_test_result.php" class="btn btn-primary mt-2 mb-2">Create Test Result</a> -->
      <div class="top-btn">
        <router-link to="awaiting-results" class="btn btn-primary mt-2 mb-2"
          >Awaiting Test Result</router-link
        >
        <router-link to="confirmed-results" class="btn btn-primary mt-2 mb-2"
          >Confirmed Test Result</router-link
        >
         <!-- <router-link to="diagnosed-cases" class="btn btn-primary mt-2 mb-2"
          >Diagnosed Test Cases</router-link
        > -->
      </div>
      <!-- Cards for Hepatitis B -->
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <Card cardTitle="Test Results for Hepatitis B">
            <Transaction
              title="Test Taken"
              :amount="tableHepatitisB.length + tableHepatitisBConfirmed.length"
              subTitle="Total number of test taken"
            />
            <Transaction
              title="Awaiting Test"
              :amount="
                tableHepatitisB.filter((i) => i.status === 'pending').length + tableHepatitisBConfirmed.filter((i) => i.status === 'pending').length
              "
              subTitle="Unconfirmed results"
            />
            <Transaction
              title="Confirmed Test"
              :amount="
                tableHepatitisBConfirmed.filter((i) => i.status === 'Confirmed').length 
              "
              subTitle="Confirmed results"
            />
            <Transaction
              title="Diagnosed Cases"
              :amount="
                tableHepatitisBConfirmed.filter((i) => i.human_result_label === 'Positive').length
              "
              subTitle="Diagnosed cases"
            />
          </Card>
        </div>

        <div
          class="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing"
        >
          <Card cardTitle="Test Results for HIV1/2">
            <Transaction
              title="Test Taken"
              :amount="tableHiv.length + tableHivConfirmed.length"
              subTitle="Total number of test taken"
            />
            <Transaction
              title="Awaiting Test"
              :amount="tableHiv.filter((i) => i.status === 'pending').length + tableHivConfirmed.filter(i => i.status === 'pending').length"
              subTitle="Unconfirmed results"
            />
            <Transaction
              title="Confirmed Test"
              :amount="tableHivConfirmed.filter((i) => i.status === 'Confirmed').length"
              subTitle="Confirmed results"
            />
             <Transaction
              title="Diagnosed Cases"
              :amount="
                tableHivConfirmed.filter((i) => i.human_result_label === 'Positive').length
              "
              subTitle="Diagnosed cases"
            />
          </Card>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <Card cardTitle="Test Results for Hepatitis C">
            <Transaction
              title="Test Taken"
              :amount="tableHepatitisC.length + tableHepatitisCConfirmed.length"
              subTitle="Total number of test taken"
            />
            <Transaction
              title="Awaiting Test"
              :amount="
                tableHepatitisC.filter((i) => i.status === 'pending').length + tableHepatitisCConfirmed.filter(i => i.status === 'pending').length
              "
              subTitle="Unconfirmed results"
            />
            <Transaction
              title="Confirmed Test"
              :amount="
                tableHepatitisCConfirmed.filter((i) => i.status === 'Confirmed').length
              "
              subTitle="Confirmed results"
            />
             <Transaction
              title="Diagnosed Cases"
              :amount="
                tableHepatitisCConfirmed.filter((i) => i.human_result_label === 'Positive').length
              "
              subTitle="Diagnosed cases"
            />
          </Card>
        </div>

        <div
          class="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing"
        >
          <Card cardTitle="Test Results for Malaria">
            <Transaction
              title="Test Taken"
              :amount="tableMalaria.length + tableMalariaConfirmed.length"
              subTitle="Total number of test taken"
            />
            <Transaction
              title="Awaiting Test"
              :amount="
                tableMalaria.filter((i) => i.status === 'pending').length + tableMalariaConfirmed.filter((i) => i.status === 'pending').length 
              "
              subTitle="Unconfirmed results"
            />
            <Transaction
              title="Confirmed Test"
              :amount="
                tableMalariaConfirmed.filter((i) => i.status === 'Confirmed').length
              "
              subTitle="Confirmed results"
            />
             <Transaction
              title="Diagnosed Cases"
              :amount="
                tableMalariaConfirmed.filter((i) => i.human_result_label === 'Positive').length
              "
              subTitle="Diagnosed cases"
            />
          </Card>
        </div>
      </div>
    </div>

    <div>
      <h5 class="pad">Awaiting Results</h5>
      <Table
        :headers="headersAwt"
        :contents="tableAwaiting"
        :actions="actions"
      />
    </div>

    <div>
      <h5 class="pad">Confirmed Results</h5>
      <Table
        :headers="headersCon"
        :contents="tableConfirmed"
        :actions="actions"
      />
    </div>
  </div>
</template>

<script>
import SideBar from "../../components/SideBar.vue";
import axios from "axios";
import NavBar from "../../components/NavBar.vue";
import Card from "../../components/Card.vue";
import Transaction from "../../components/Transaction.vue";
import Table from "../../components/Table.vue";

export default {
  name: "Results",
  data() {
    return {
      results: [],
      tableAwaiting: [],
      tableConfirmed: [],
      tableHepatitisB: [],
      tableHiv: [],
      tableHepatitisC: [],
      tableMalaria: [],
      tableHepatitisBConfirmed: [],
      tableHivConfirmed: [],
      tableHepatitisCConfirmed: [],
      tableMalariaConfirmed: [],
      headersCon: [],
      headersAwt: [],
      awaiting: [],
      confirmed: [],
      actions: [
        {
          btnType: "btn btn-primary",
          val: "View",
        },
      ],
    };
  },
  components: {
    SideBar,
    NavBar,
    Card,
    Transaction,
    Table,
  },
  methods: {
    async fetch() {
      let loading = this.$loading.show();
      axios
        .get("test-results/confirmed-test-result")
        .then((response) => {
          this.results = [...response.data.data.data, ...this.results];
          this.confirmed = response.data.data.data;

          // classification of results
          this.tableHepatitisBConfirmed = this.confirmed.filter(
            (i) => i.test_type === "Hepatitis B"
          );
          this.tableHivConfirmed = this.confirmed.filter((i) => i.test_type === "Typhoid");
          this.tableHepatitisCConfirmed = this.confirmed.filter(
            (i) => i.test_type === "Hepatitis C"
          );
          this.tableMalariaConfirmed = this.confirmed.filter(
            (i) => i.test_type === "Malaria"
          );
          //end of classification

          this.tableConfirmed = this.extract(this.confirmed, "con");
          this.headersCon = Object.keys(this.tableConfirmed[0]);
          this.$notify({
            type: "success",
            title: "Notification",
            text: response.data.message || "success",
          });
        })
        .catch((err) => {
          loading.hide();
          this.$notify({
            type: "error",
            title: "Notification",
            text: err.message || "Operation Failed",
          });
        });
      axios
        .get("test-results/pending-test-result")
        .then((response) => {
          this.results = [...response.data.data.data, ...this.results];
          this.pending = response.data.data.data

          // classification of results
          this.tableHepatitisB = this.pending.filter(
            (i) => i.test_type === "Hepatitis B"
          );
          this.tableHiv = this.pending.filter((i) => i.test_type === "Typhoid");
          this.tableHepatitisC = this.pending.filter(
            (i) => i.test_type === "Hepatitis C"
          );
          this.tableMalaria = this.pending.filter(
            (i) => i.test_type === "Malaria"
          );
          //end of classification

          this.awaiting = response.data.data.data;
          this.tableAwaiting = this.extract(this.awaiting, "awt");
          this.headersAwt = Object.keys(this.tableAwaiting[0]);
          loading.hide();
          this.$notify({
            type: "success",
            title: "Notification",
            text: response.data.message || "success",
          });
        })
        .catch((err) => {
          loading.hide();
          this.$notify({
            type: "error",
            title: "Notification",
            text: err.message || "Operation Failed",
          });
        });
    },
    extract(arr, type) {
      let data = [];
      arr.forEach(
        ({
          first_name,
          test_id,
          last_name,
          contact_number,
          result_confirmed_by,
          test_type,
          status,
          state,
          created_at,
        }) => {
          let name = `${first_name} ${last_name}`;
          let time_stamp = `${new Date(created_at).toLocaleString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            hour12: true,
            minute: "2-digit",
            timeZone: "Europe/Paris",
          })}`;
          switch (type) {
            case "awt":
              data.push({
                id: test_id,
                name,
                contact_number,
                test_type,
                status,
                state,
                time_stamp,
              });
              break;
            case "con":
              data.push({
                id: test_id,
                name,
                contact_number,
                test_type,
                status,
                result_confirmed_by,
                state,
                time_stamp,
              });
              break;

            default:
              break;
          }
        }
      );
      return data;
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style scoped>
.top-btn {
  display: flex;
  gap: 1em;
}
.pad {
  padding-left: 0.8em;
}
</style>