<template>
  <div class="form">
    <div class="form-container outer">
        <div class="form-form">
            <div class="form-form-wrap">
                <div class="form-container">
                    <div class="form-content">

                        <h1 class="">Skrind Admin</h1>
                        <p class="">Log in to your account to continue.</p>
                        <form class="text-left" @submit.prevent="login">
                            <div class="form">
                                <div id="username-field" class="field-wrapper input">
                                    <label for="email">EMAIL ADDRESS</label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                     <input id="email" v-model="email" type="text" class="form-control" placeholder="e.g John_Doe" required>
                                </div>
                                <div id="password-field" class="field-wrapper input mb-2">
                                    <div class="d-flex justify-content-between">
                                        <label for="password">PASSWORD</label>
                                        <a href="" class="forgot-pass-link">Forgot Password?</a>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                     <input id="password" v-model="password" type="password" class="form-control" placeholder="Password" required>
                                </div>
                                <div class="d-sm-flex justify-content-between">
                                    <div class="field-wrapper">
                                         <button type="submit" class="btn btn-primary d-block" :disabled="loading">Login</button>
                                    </div>
                                </div>
                                <div class="division">
                                      <span>OR</span>
                                </div>
                                <p class="signup-link">Not login in ? <a href="">Visit main website</a></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <notifications position="bottom right"/>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Home',
  data() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    onComplete(token, email) {
      this.$store.commit("login", token);
      this.$store.commit("setEmail", email);
      this.$router.go() // this refreshes the page
    },
    login(){
      let loading = this.$loading.show();
      let data = {
        email: this.email,
        password: this.password
      }
      axios
        .post("login", data)
          .then(response => {
            loading.hide();    
            this.$router.push('/dashboard', this.onComplete(response.data.data.token, response.data.data.email) )
          })
          .catch(err => {
            loading.hide();
            this.$notify({
              type: "error",
              title: "Notification",
              text: err.message || "Operation Failed"
            });
          });
    }
  }

}
</script>
