<template>
    <!-- Content -->
    <div class="col-xl-12 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">

        <div class="user-profile layout-spacing">
            <div class="widget-content widget-content-area">
                <div class="d-flex justify-content-between">
                    <h3 class="">Store Details</h3>
                    <router-link @click="setCurrView" :to="'/update-store/'" class="btn btn-primary">Edit</router-link>
                </div>
                <div class="text-center user-info">
                    <img :src="storeDetails.image_url || 'https://image.shutterstock.com/image-photo/does-random-employee-drug-testing-260nw-1211128711.jpg'" alt="avatar" height="300">
                    <p class="">{{ storeDetails.name}}</p>
                </div>
                <div class="user-info-list">

                    <div class="">
                        <ul class="contacts-block list-unstyled" >
                            <li class="contacts-block__item">
                                <span class="text-primary">Id: </span> <span class="bold">{{ storeDetails.id }}</span>
                            </li>                            
                            <li class="contacts-block__item">
                                <span class="text-primary">Name: </span> <span class="bold">{{ storeDetails.name }}</span>
                            </li>                            
                            <li class="contacts-block__item">
                                <span class="text-primary">Address: </span> <span class="bold">{{ storeDetails.address }}</span>
                            </li>                            
                            <li class="contacts-block__item">
                                <span class="text-primary">created_at: </span> <span class="bold">{{ storeDetails.created_at }}</span>
                            </li>                            
                            <li class="contacts-block__item">
                                <span class="text-primary">updated_at: </span> <span class="bold">{{ storeDetails.updated_at }}</span>
                            </li>                            
                        </ul>
                    </div>                                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {    
                id: this.$route.params.id,
                storeDetails: {},
            }
        },
        methods: {
            async _fetch() {
                let loading = this.$loading.show()
                axios.get(`locate-kit-stores/${this.id}`)
                    .then(response => {
                        this.storeDetails = response.data.data
                        console.log(response.data.data);
                        loading.hide()
                        this.$notify({
                            type: "success",
                            title: "Notification",
                            text: response.data.message || "success",
                        });
                    }).catch(err => {
                        console.log(err);
                        loading.hide()
                        this.$notify({
                            type: "error",
                            title: "Notification",
                            text: err.message || "Operation Failed" ,
                        });
                    })
            },
            // stores the current store being viewed in the vuex store
            setCurrView() {
                this.$store.commit("setCurrViewStore", this.storeDetails)
            }
        },
        created() {
            this._fetch()
        }
    }
</script>

<style scoped>
ul{ 
        width: 100%;
        max-width: 100%;
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(3, 1fr);
    }

    ul.contacts-block{
        max-width: 100% !important;
    }
</style>