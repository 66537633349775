import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";


const store = createStore({
  state: {
    isLogged: false,
    token: null,
    admToDelete: "",
    user: '',
    sideBar: false,
    email: "",
    selectedKitCode: "",
    selectedStoreName: "",
    currViewStore: {}
  },
  mutations: {
    login(state, token){
      state.token = token
      state.isLogged = true
    },
    logout(state){
      state.token = null
      state.isLogged = false
    },
    admToDelete(state, obj) {
      state.admToDelete = obj
    },
    setUser(state, user) {
      state.user = user
    },
    setSideBar(state, bool) {
      state.sideBar = bool
    },
    setEmail(state, email) {
      state.email = email
    },
    setSelectedKitCode(state, code) {
      state.selectedKitCode = code
    },
    setSelectedStoreName(state, data) {
      state.selectedStoreName = data
    },
    setCurrViewStore(state, data) {
      state.currViewStore = data
    }
  },
  getters: {
    isLogged: state => state.isLogged,
    token: state => state.token,
    admToDelete: state => state.admToDelete,
    getUser: state => state.user,
    getSideBar: state => state.sideBar,
    getEmail: state => state.email,
    getSelectedKitCode: state => state.selectedKitCode,
    getSelectedStoreName: state => state.selectedStoreName,
    getCurrViewStore: state => state.currViewStore
  },
  plugins: [createPersistedState()]
});

export default store;
