<template>
    <div>
        <h2>Oooops, Page Not Found</h2>
    </div>
</template>

<script>
    export default {
        
    }
</script>

