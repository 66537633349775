<template>
    <Table :headers="headers" :contents="contents" :actions="actions">
    
    </Table>
</template>

<script>
import Table from '../../components/Table.vue'
    export default {
        data() {
            return {
                headers: [],
                contents: [],
                actions: [
                    {
                        btnType: "btn btn-primary",
                        val: "View"
                    }
                ]
            }
        },
        components: {
            Table
        },
        methods: {
            _fetchData() {
                let loading = this.$loading.show()
                axios.get("test-results/pending-test-result")
                .then(res => {
                    this.contents = this.extract(res.data.data.data);
                    this.headers = Object.keys(this.contents[0])
                    loading.hide()
                    this.$notify({
                        type: "success",
                        title: "Notification",
                        text: res.data.message || "success",
                    });
                })
            },
                extract(arr){
                let data = []
                arr.forEach(({ first_name, test_id, last_name, contact_number, test_type, status, state, created_at }) => {
                    let name = `${first_name} ${last_name}`
          let time_stamp = `${new Date(created_at).toLocaleString("en-GB", {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', hour12:true, minute: '2-digit',timeZone: "Europe/Paris"})}`
                    data.push({ id: test_id, name, contact_number, test_type, status, state, time_stamp })
                })
                return data
            }
        },
        created() {
            this._fetchData()
        }
        
    }
</script>

