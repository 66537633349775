<template>
    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
        <router-link v-show="role=== 'SUPER_ADMIN'" :to="{name: 'CreateKit'}" class="btn btn-primary mt-2 mb-2">Create Kit Code</router-link>
        
        <Card cardTitle="Kit Codes Generator">
            <Transaction title="Total kit Code" subTitle="kit codes generated" :amount="kits.length" :inc="true"/>
        </Card>
    </div>

        

        <Table  :headers="headers" :contents="content" :actions="actions" />

        <Modal :arg="[selectedKitCode]" :handler="reactivateKit" accept="Reactivate" id="reactivate" reject="Abort" title="Reactivate Kit" >
            Are you sure you want to reactivate kit {{selectedKitCode}} ?
        </Modal>
</template>

<script>
import SideBar from '../../components/SideBar.vue';
import NavBar from '../../components/NavBar.vue';
import axios from 'axios'
import Card from '../../components/Card.vue';
import Transaction from '../../components/Transaction.vue';
import Table from '../../components/Table.vue';
import Footer from '../../components/Footer.vue';
import Modal from '../../components/Modal.vue';
import store from "../../store"
export default {
	name: 'Kit',
	data(){
		return {
			role:"",
			user: {},
			kits: [],
            headers: [],
            content: [],
            actions: [
                {
                    btnType: "btn btn-primary",
                    val: "Reactivate"
                }
            ]
		}
	},
	components:{
        SideBar,
        NavBar,
        Card,
        Transaction,
        Table,
        Footer,
		Modal
	},
	methods:{
		async fetch(){
			let loading = this.$loading.show();
			axios
				.get('kit-codes')
					.then(response => {
						this.kits = response.data.data.data
                        this.headers = Object.keys(response.data.data.data[0])
                        this.content = response.data.data.data
						console.log(this.content) // results for kits page
                        loading.hide()
						this.$notify({
							type: "success",
							title: "Notification",
							text: response.data.message,
						});
					})
					.catch(err => {
						loading.hide()
						this.$notify({
							type: "error",
							title: "Notification",
							text: err.message || "Operation Failed" ,
						});
					})
								
		},
		getStatusClass(kit){
			return {
				'badge outline-badge-success': kit.status == 'ACTIVE', 
				'badge outline-badge-danger': kit.status == 'USED'
            }
		},
		async reactivateKit(code){
			let loading = this.$loading.show();
			axios.post(`reactivate-kit-code?code=${code}`)
				.then(response => {
					loading.hide()
					location.reload()
					this.$notify({
						type: "success",
						title: "Notification",
						text: response.data.message,
					});
				}).catch(() => {
					loading.hide()
					location.reload()
					this.$notify({
						type: "failure",
						title: "Notification",
						text: "Failed to Re-activate kit",
					});
				})
		}
	},
	computed: {
		selectedKitCode(){
			return this.$store.getters.getSelectedKitCode
		}
	},
	created(){
		this.fetch();
		this.role = store.getters.getUser.role;
	}
}
</script>

<style>

</style>