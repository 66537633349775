import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Admin from '../views/admin/Admin.vue'
import CreateAdmin from '../views/admin/CreateAdmin.vue'
import Test from '../views/test/Test.vue' //work on
import UpdateTest from '../views/test/UpdateTest.vue'
import CreateTest from '../views/test/CreateTest.vue'
import Kit from '../views/kit/Kit.vue' //work on
import CreateKit from '../views/kit/CreateKit.vue'
import UpdateUser from '../views/user/UpdateUser.vue'
import Results from '../views/result/Results.vue'
import Result from '../views/result/Result.vue'
import UpdateResult from '../views/result/UpdateResult.vue'
import AwaitingResults from "../views/result/AwaitingResults.vue"
import ConfirmedResults from "../views/result/ConfirmedResults.vue"
import DiagnosedCases from "../views/result/DiagnosedCases.vue"
import NotFound from "../views/NotFound"
import NoPermission from "../views/NoPermission"
import CreateStore from "../views/locate-store/CreateStore.vue"
import Stores from "../views/locate-store/Stores.vue"
import Store from "../views/locate-store/Store.vue"
import UpdateStore from "../views/locate-store/UpdateStore.vue"



const isAuth = (to, from, next) => {
  if (store.getters.isLogged) {
    next('/dashboard')
    return  
  }
  next()
}

const isLogged = (to, from, next) => {
  if (store.getters.isLogged) {
    if(to.path == "/admin"  && store.getters.getUser.role == "ADMIN" || to.path == "/results" && store.getters.getUser.role == "ADMIN"){
      next('/no-permission')
      return
    } else {
      next()
      return
    }
  }
  next('/')
}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: isAuth
  },
  {
  path: '/dashboard',
  name: 'Dashboard',
  component: Dashboard,
  beforeEnter: isLogged
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: isLogged
  },
  {
    path: '/create-admin',
    name: 'CreateAdmin',
    component: CreateAdmin,
    beforeEnter: isLogged
  },
  {
    path: '/tests',
    name: 'Test',
    component: Test,
    beforeEnter: isLogged
  },
  {
    path: '/update-test/:id',
    name: 'UpdateTest',
    component: UpdateTest,
    beforeEnter: isLogged
  },
  {
    path: '/create-test',
    name: 'CreateTest',
    component: CreateTest,
    beforeEnter: isLogged
  },
  {
    path: '/kits',
    name: 'Kit',
    component: Kit,
    beforeEnter: isLogged
  },
  {
    path: '/create-kit',
    name: 'CreateKit',
    component: CreateKit,
    beforeEnter: isLogged
  },
  {
    path: '/update-user',
    name: 'UpdateUser',
    component: UpdateUser,
    beforeEnter: isLogged
  },
  {
    path: '/results',
    name: 'Results',
    component: Results,
    beforeEnter: isLogged
  },
  {
    path: '/result/:id',
    name: 'Result',
    component: Result,
    beforeEnter: isLogged
  },
  {
    path: '/update-result/:id',
    name: 'UpdateResult',
    component: UpdateResult,
    beforeEnter: isLogged
  },
  { path: '/awaiting-results/',
    name: 'AwaitingResults',
    component: AwaitingResults,
    beforeEnter: isLogged
  },
  {
    path: '/confirmed-results/',
    name: 'ConfirmedResults',
    component: ConfirmedResults,
    beforeEnter: isLogged
  },
  // {
  //   path: '/diagnosed-cases/',
  //   name: 'DiagnosedCases',
  //   component: DiagnosedCases,
  //   beforeEnter: isLogged
  // },
  {
    path: '/no-permission/',
    name: 'No Access',
    component: NoPermission,
    beforeEnter: isLogged
  },
  {
    path: '/stores',
    name: 'Stores',
    component: Stores,
    beforeEnter: isLogged
  },
  {
    path: '/store/:id',
    name: 'Store',
    component: Store,
    beforeEnter: isLogged
  },
  {
    path: '/update-store/',
    name: 'Update Store',
    component: UpdateStore,
    beforeEnter: isLogged
  },
  {
    path: '/create-store/',
    name: 'Create Store',
    component: CreateStore,
    beforeEnter: isLogged
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404 page not found',
    component: NotFound,
    beforeEnter: isLogged
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
