<template>
  <main class="sidebar-noneoverflow">
    <div class="account-content">
      <div
        class="scrollspy-example"
        data-spy="scroll"
        data-target="#account-settings-scroll"
        data-offset="-100"
      >
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
            <form id="contact" class="section contact"  @submit.prevent="update(test_id)">
              <div class="info">
                <h5 class="">Test Type</h5>
                <div class="row">
                  <div class="col-md-11 mx-auto">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="test_type">Test Type</label>
                          <input
                            type="text"
                            class="form-control mb-4"
                            v-model="test['name']"
                            id="test_type"
                            placeholder="Malaria"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="description">Description</label>
                          <textarea
                            class="form-control mb-4"
                            v-model="test['description']"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <button class="btn btn-primary">Edit test type</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SideBar from "../../components/SideBar.vue";
import axios from "axios";
import NavBar from "../../components/NavBar.vue";

export default {
  name: "UpdateTest",
  data() {
    return {
      test_id: "",
      test: {},
    };
  },
  components: {
    SideBar,
    NavBar,
  },
  created() {
    let loading = this.$loading.show();
    let id = this.$route.params.id;
    this.test_id = this.$route.params.id;
    axios.get("test-types/" + id).then((response) => {
      console.log(response.data);
      // this.test = response.data.data.data
      this.test["name"] = response.data.data.name;
      this.test["description"] = response.data.data.description;
      loading.hide();
      this.$notify({
        type: "success",
        title: "Notification",
        text: response.data.message || "success",
      });
    });
  },
  methods: {
    update(id) {
      let loading = this.$loading.show();
      let data = {
        name: this.test["name"],
        description: this.test["description"],
      };
      axios
        .patch("test-types/" + id, data)
        .then((response) => {
          loading.hide();
          this.$notify({
            type: "success",
            title: "Notification",
            text: response.data.message || "success",
          });
        })
        .catch((err) => {
          loading.hide();
          this.$notify({
            type: "error",
            title: "Notification",
            text: err.message || "Operation Failed",
          });
        });
    },
  },
};
</script>

<style>
</style>